import React, { useState } from 'react';
import { Page, Text, View, Image, Document, StyleSheet, Font } from '@react-pdf/renderer';

import dayjs from 'dayjs';


Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#FFF'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    title: {
        color: "#9f0028",
        fontSize: 18,
        marginTop: 40,
        textTransform: "uppercase"
    },
    author: {
        textAlign: 'center',
        marginBottom: 40,
    },
    subtitle: {
        marginTop: 5,
        marginBottom: 5,
        fontSize: 12
    },
    text: {
        margin: 12,
        textAlign: 'justify',
    },
    image: {
        position: "absolute",
        top: "20px",
        left: "45%",
        width: "150px"
    },
    emphasis: {
        margin: 12,
        fontSize: 24,
        color: '#9f0028',
        fontFamily: 'Oswald'
    },
    header: {
        marginBottom: 20,
        textAlign: 'center',
        color: 'grey',
    },
    pageNumber: {
        position: 'absolute',
        bottom: 10,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'black',
        fontSize: 8
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        borderColor: "white",

    },

    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },

    tableCol: {
        width: "9%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderColor: "white",
        backgroundColor: "#F3F3F3"
    },
    tableColHeader: {
        width: "9%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderColor: "white",
        backgroundColor: "#9f0028",
        color: "white",
        fontWeight: "bold"
    },
    tableCell: {
        // margin: "auto",
        margin: 2,
        fontSize: 8
    },
    date: {
        fontStyle: "italic",
        color: "grey",
        position: "absolute",
        top: 5,
        right: 5,
        fontSize: 8
    }
});


// Create Document Component
export default function TemplateInformes({ info, title, ccaa, sede, inicio, fin }) {

    function secondsToString(seconds) {
        var hour = Math.floor(seconds / 3600);
        hour = (hour < 10) ? '0' + hour : hour;
        var minute = Math.floor((seconds / 60) % 60);
        minute = (minute < 10) ? '0' + minute : minute;
        var second = seconds % 60;
        second = (second < 10) ? '0' + second : second;
        return hour + ':' + minute;
    }

    return (
        <Document>
            <Page style={styles.body} wrap orientation='landscape'>
                {/* <Text style={styles.title}>{ccaa == "Todas" ? title + "todas las comunidades" : sede ? title + ccaa + " - " + sede : title + ccaa}</Text> */}
                <Text style={styles.title}>Fichajes</Text>
                <Text style={styles.subtitle}>{inicio ? "Desde " + new Date(inicio).toLocaleDateString() + " hasta " + new Date(fin).toLocaleDateString() : "Hasta el día de hoy: " + new Date(fin).toLocaleDateString()}</Text>
                <Image
                    style={styles.image}
                    src="/img/logo2.png"
                />

                <Text style={styles.subtitle}>
                    Informe generado automáticamente desde la aplicación de fichajes
                </Text>

                {/* <Text style={styles.date}>Fecha de generación: {new Date().toLocaleDateString()}</Text> */}

                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCell}>DNI</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCell}>Nombre</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCell}>Apellidos</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCell}>CCAA</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCell}>Sede</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCell}>Horas totales</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCell}>Horas de descanso</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCell}>Horas médicas</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCell}>Horas compensación</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCell}>Vacaciones</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCell}>Días propios</Text>
                        </View>
                    </View>
                    {info.map((item, index) => (
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item.dni}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item.nombre}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item.apellidos}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item.ccaa}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item.sede}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{secondsToString(item.horas_totales)}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{secondsToString(item.horas_descanso)}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{secondsToString(item.horas_medicas)}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{secondsToString(item.horas_compensacion)}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item.vacaciones}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{item.diasPropios}</Text>
                            </View>
                        </View>
                    ))}
                </View>
            </Page>
        </Document>
    )
}
